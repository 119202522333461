#root {
  background-color: #f5f5f5;
  height: 100%;
  min-height: 100vh;
}

.App {
  text-align: center;
  max-width: 1200px;
  margin: auto;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.navbar ul a.active {
  color: rgb(34 197 94);
 }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Start product item slide */
.react-multiple-carousel__arrow--right {
  right: calc(-1% + 1px) !important;
}

.react-multiple-carousel__arrow--left {
  left: calc(-1% + 1px) !important;
}

.react-multiple-carousel__arrow {
  border-radius: 8px !important;
  min-width: 40px !important;
  min-height: 60px !important;
  background-color: #00000026 !important;
}
/* End product item slide */

.error {
  color: #e01020 !important;
}

.thumbs-wrapper,
.thumbs-wrapper ul {
  margin: 0px;
}

.carousel.carousel-slider .control-arrow {
  background: #ddd !important;
  height: 50px;
  margin-top: 28% !important;
}
